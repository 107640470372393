import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import Spin from '../../components/Spin/Spin';
import stores from '../../stores/stores';
import styles from './styles.module.css';

const SpecialsBranch = observer(() => {
  const { branchId } = useParams<{
    branchId: string | undefined,
  }>();
  const { branchesStore, specialsStore } = stores;
  const mapSetRef = useRef(false);
  const history = useHistory();

  useEffect(() => {
  }, []);

  const retryCount = useRef(0);

  const handleLoadMapElements = () => {
    if (retryCount.current >= 10) {
      return;
    }

    if (!mapSetRef.current) {
      try {
        specialsStore.removeMaps();
        specialsStore.specialMaps.forEach((item) => {
          specialsStore.addMapRefs(item.imageMaps);
        });
        mapSetRef.current = true;
      } catch {
        retryCount.current += 1;
        setTimeout(() => handleLoadMapElements(), 1000 * retryCount.current);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (specialsStore.mainPageData.length === 0) {
        await specialsStore.loadSpecials();
      }

      if (branchesStore.branches.value.data.length === 0) {
        await branchesStore.loadBranchNames();
      }

      const value = branchesStore.branches.value.data.find((x) => x.id === branchId);
      specialsStore.setCurrentBranch(value ?? null);

      const redirectToNational = specialsStore.mainPageData.findIndex((x) => x.redirect) >= 0;
      if (redirectToNational || specialsStore.specialMaps.length === 0) {
        history.push('/specials/national');
        specialsStore.setCurrentBranch(null);
      }

      setTimeout(() => {
        handleLoadMapElements();
      }, 1000);
    })();
  }, [branchId]);

  // eslint-disable-next-line
  useEffect(() => {
    return () => {
      specialsStore.removeMaps();
      specialsStore.setCurrentBranch(undefined);
      mapSetRef.current = false;
    };
  }, []);

  return (
    <main
      id="specials-branch"
    >
      <Helmet>
        <title>
          {specialsStore.currentBranch ? `${specialsStore.currentBranch.branchName} Specials - G. Fox` : 'National Specials - G. Fox'}
        </title>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
          integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
          crossOrigin=""
        />
        <link
          rel="stylesheet"
          href="https://unpkg.com/@geoman-io/leaflet-geoman-free@latest/dist/leaflet-geoman.css"
        />
      </Helmet>
      <Spin
        spinning={branchesStore.branches.isLoading || specialsStore.specials.isLoading
         || specialsStore.inventory.isLoading}
      >
        <div className={styles.specialsCon}>
          {(specialsStore.specialMaps).map((specialItem) => (
            <div key={specialItem.id}>
              {specialItem.imageMaps.map((mapItem) => (
                <div
                  key={mapItem.id}
                  id={specialsStore.genMapId(mapItem.id)}
                  className={styles.mapCon}
                />
              ))}
            </div>
          ))}
        </div>
      </Spin>
    </main>
  );
});

export default SpecialsBranch;
