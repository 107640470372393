/* eslint-disable import/prefer-default-export */
import Images from '../../assets';
import { ITransparentBgImageOpts } from '../../components/CTA/TransparentBgImage';

export interface ICarouselData {
  backgroundImage: ITransparentBgImageOpts;
  headerText: string;
  subHeaderText: string;
  subText: string[];
}

export const carouselData: ICarouselData[] = [
  {
    backgroundImage: { largeScreen: Images.MainSlider1 },
    headerText: 'BROAD BASED BLACK ECONOMIC EMPOWERMENT',
    subHeaderText: 'B-BBEE',
    subText: [
      // eslint-disable-next-line max-len
      'G. Fox (Pty) Ltd, a division of Bidvest Commercial Products, are proud to be one of the leading contributors to Broad Based Black Economic Empowerment in the PPE industry.',
    ],
  },
];

export const Documents = [
  {
    name: 'G.Fox & Co BEE Verification Certificate',
    image: Images.PdfIcon,
    // eslint-disable-next-line max-len
    docUrl: 'https://gfox-cdn.bridgelabs.design/documents/legal/B-BBEE-VERIFICATION-CERTIFICATE-BIDVEST-COMMERCIAL%20PRODUCTS-(PTY)-LTD-TA-G-FOX-%26-CO%20-2024-2025.pdf',
  },
  {
    name: 'Bidvest Group BEE Certificate',
    image: Images.PdfIcon,
    docUrl: 'https://gfox-cdn.bridgelabs.design/documents/legal/BEE-Certificate-2024.pdf',
  },
  {
    name: 'Bidvest Group BEE Rating Annexure',
    image: Images.PdfIcon,
    docUrl: 'https://gfox-cdn.bridgelabs.design/documents/legal/BEE-Annexure-2024.pdf',
  },
];
